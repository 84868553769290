@font-face {
  font-family: 'Formular';
  font-style: normal;
  font-weight: 400;
  src: url('../src/assets/fonts/Formular.eot'); /* IE9 Compat Modes */
  src: local('Formular'), local('Formular'), url('../src/assets/fonts/Formular.eot') format('embedded-opentype'),
    /* IE6-IE8 */ url('../src/assets/fonts/Formular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../src/assets/fonts/Formular.woff') format('woff'),
    /* Modern Browsers */ url('../src/assets/fonts/Formular.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Formular';
  font-style: normal;
  font-weight: 500;
  src: url('../src/assets/fonts/Formular-Medium.eot');
  src: local('Formular Medium'), local('Formular-Medium'),
    url('../src/assets/fonts/Formular-Medium.eot?#iefix') format('embedded-opentype'),
    url('../src/assets/fonts/Formular-Medium.woff2') format('woff2'),
    url('../src/assets/fonts/Formular-Medium.woff') format('woff'),
    url('../src/assets/fonts/Formular-Medium.ttf') format('truetype');
}

html {
  font-size: 16px;
  overflow: overlay;
}

body {
  margin: 0;
  font-family: 'Formular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* styles for toast progress-bar */
.Toastify__progress-bar--dark {
  background: linear-gradient(to right, #33B415, #237A0E) !important;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
